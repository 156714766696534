import React from "react";
import { Row, Col, Layout } from "antd";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./App.css";
import Index from "./pages/index";
import Background from "./LiquidCheese.svg";

const { Content } = Layout;

class App extends React.Component {
  state = {
    theme: "light",
    current: "mail",
    width: 0,
    height: 0
  };

  changeTheme = value => {
    this.setState({
      theme: value ? "dark" : "light"
    });
  };

  handleClick = e => {
    console.log("click ", e);
    this.setState({
      current: e.key
    });
  };

  render() {
    let navheight = "10vh";
    console.log("setheigt");
    if (window.innerHeight * 0.1 >= 60) {
      navheight = "60px";
      console.log("updated to 60");
    }
    const navbarstyle = {
      paddingTop: "25px",
      lineHeight: navheight
    };

    let colSizeNav = 22;
    let colSizeNon = 1;
    if ((window.innerWidth / 24) * 22 >= 600) {
      console.log("enered if");
      colSizeNav = Math.floor(600 / (window.innerWidth / 24));
      console.log("new col span" + colSizeNav);
      if (colSizeNav % 2 !== 0) colSizeNav++;
      colSizeNon = (24 - colSizeNav) / 2;
    }
    return (
      <Router>
        <Layout>
          <div
            style={{
              backgroundImage: "url(" + Background + ")",
              backgroundSize: "cover",
              minHeight: "100vh",
              minWidth: "100hw"
            }}
          >
            <Row id="headerbar" style={navbarstyle}>
              <Col span={colSizeNon}></Col>
              <Col span={colSizeNav} align="center">
                <ul
                  style={{
                    background: "#FFC5B0",
                    borderRadius: "25px",
                    listStyleType: "none"
                  }}
                >
                  <li>
                    {/* <Icon type="info-circle" theme="twoTone" /> */}
                    <span>About me</span>
                    <Link to="/" />
                  </li>
                </ul>
              </Col>
              <Col span={colSizeNon}></Col>
            </Row>
            <Content>
              <div>
                <Route exact path="/" component={Index} />
                <Route
                  path="/pricing-eval"
                  component={() => {
                    window.location.href =
                      "https://pricing.sebastian-schilling.de";
                    return null;
                  }}
                />
              </div>
            </Content>
          </div>
        </Layout>
      </Router>
    );
  }
}

export default App;
