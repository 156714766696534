import React from "react";
import { Row, Col, Typography } from "antd";
import "../App.css";
import tenor from "../tenor.gif";
const { Title } = Typography;

class Index extends React.Component {
  render() {
    return (
      <Row style={{ paddingTop: "20px" }}>
        <Col span={3}></Col>
        <Col span={18} align="center">
          <div
            style={{
              background: "#FFC5B0",
              borderRadius: "25px",
              paddingLeft: "25px",
              paddingRight: "25px",
              paddingTop: "15px",
              paddingBottom: "15px",
              height: "%100vh"
            }}
          >
            <Title level={4}> Bisher noch recht leer</Title>
            <span>
              <p>
                Momentan gibts hier noch nicht viel zu sehen, aber eventell wird
                in Zukunft etwas hier erscheinen :)
              </p>
              <p>
                <a href="https://tenor.com/view/where-the-hell-am-i-where-am-i-lost-in-afield-confused-gif-11092516">
                  <img
                    src={tenor}
                    alt="loading ..."
                    style={{ maxWidth: "80%", maxHeight: "80%" }}
                  />
                </a>
              </p>
            </span>
          </div>
        </Col>
        <Col span={3}></Col>
      </Row>
    );
  }
}

export default Index;
